<template>
  <div class="topic-assignment-submission-page">
    <v-card
      v-if="submission"
      class="mb-2"
      :color="submission.accepted ? 'green lighten-4' : 'yellow lighten-4'"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-wrap body-2"
            >This submission <span v-if="!submission.accepted">hasn't yet </span
            ><span v-else>has</span> been
            <strong>graded & accepted.</strong></v-list-item-title
          >
        </v-list-item-content>

        <v-list-item-icon v-if="currentUser.is_faculty"
          ><v-btn
            v-if="!submission.accepted"
            @click="showAcceptDialog = true"
            small
            color="primary"
            ><v-icon class="mr-2">mdi-check</v-icon>Grade & Accept</v-btn
          ><v-btn
            v-else
            @click="showUndoAcceptDialog = true"
            small
            color="primary"
            ><v-icon class="mr-2">mdi-undo</v-icon> Undo</v-btn
          ></v-list-item-icon
        >
      </v-list-item>
    </v-card>

    <v-card
      v-if="submission.accepted && submission.grade"
      class="mb-2"
      color="green lighten-3"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-wrap body-1"> <strong>Grade:</strong></v-list-item-title>
        </v-list-item-content>

        <!-- <v-list-item-icon><strong>{{submission.grade}}/5</strong></v-list-item-icon> -->
        <v-list-item-icon>
          <v-rating
          readonly
          :value="submission.grade"
          color="primary"
          background-color="primary"
          />
        </v-list-item-icon>

      </v-list-item>
    </v-card>

    <v-row justify="center">
      <v-col md="6" v-if="isLoading">
        <v-skeleton-loader
          class="mt-2"
          type="list-item-avatar, list-item-three-line"
        ></v-skeleton-loader>
      </v-col>
      <v-col md="6" v-if="submission && !isLoading">
        <v-list-item>
          <v-list-item-avatar
            ><v-img :src="submission.student.image"></v-img
          ></v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{
              submission.student.full_name
            }}</v-list-item-title>
            <v-list-item-subtitle>{{
              moment(submission.created).format("lll")
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <div class="submission-content mx-4">
          <div class="mx-2 my-2">
            <p class="mb-0">{{ submission.description }}</p>
          </div>

          <v-list
            class="my-0 py-0"
            v-if="
              submission &&
              submission.topic_assignment_submission_documents.length
            "
          >
            <v-list-item
              class="px-0"
              color="red"
              v-for="submissionDocument in submission.topic_assignment_submission_documents"
              :key="submissionDocument.id"
            >
              <v-list-item-avatar>
                <v-icon>mdi-file</v-icon>
              </v-list-item-avatar>
             <v-list-item-content class="py-0">
                  <v-list-item-subtitle class="font-weight-bold" v-if="submissionDocument.file_name.split('.').length > 1">
                    {{submissionDocument.file_name.split('.').slice(-1)[0].toUpperCase() }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="subtitle-2 text-wrap">
                  {{ submissionDocument.file_name }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              <v-list-item-icon class="align-center">
                <v-btn
                  outlined
                  color="primary"
                  class="non-focused"
                  v-if="submissionDocument.progress == 100"
                  @click="openFile(submissionDocument)"
                  ><v-icon>mdi-check</v-icon> Open</v-btn
                >
                <v-btn
                  @click="downloadDocument(submissionDocument)"
                  color="primary"
                  class="non-focused"
                  icon
                  v-else
                >
                  <v-icon v-if="submissionDocument.progress == null"
                    >mdi-download</v-icon
                  >
                  <span v-else>{{ submissionDocument.progress }}%</span>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col md="6">
        <submission-discussion
          :key="submissionDiscussionKey"
          class="mx-4"
          v-if="submission"
          :submission-id="submission.id"
          ref="submissionDiscussion"
          :endpoint="endpoints.topicAssignmentSubmissionReplies"
        />
      </v-col>
    </v-row>
    <!-- edit assignment sheet (student) -->
    <intract-create-edit-sheet
    persistent

      v-if="currentUser.is_student"
      class="assignment-submission-edit-sheet"
      title="Edit Assignment"
      description="Write a description about your submission and attach necessary files"
      :visible="submissionSheet.visible"
      @close="submissionSheet.visible = false; submissionSheet.editId = null;"
      :fields="submissionFormFields"
      :endpoint="endpoints.topicAssignmentSubmissions"
      :edit-id="submissionSheet.editId"
      :data-object="submissionSheet.obj"
      :create-success-message="submissionSheet.createSuccessMessage"
      enable-media
      @objectCreated="getSubmissionDetails"
      @updateObject="(obj) => (submissionSheet.obj = obj)"
    />
    <!-- accept submission dialog -->
    <confirmation-dialog
      title="Are you sure you want to grade & accept this submission?"
      description="This action will notify the student about the acceptance & grade of their submission. You can always undo this action."
      :visible="showAcceptDialog"
      @successCallback="acceptSubmission"
      @failureCallback="showAcceptDialog = false"
      :disableSuccessButton="!assignmentGradingForm"
    >
      <template v-slot:custom-dialog-body>
        <v-form v-model="assignmentGradingForm">
          <v-input
          :value="submissionSheet.obj.grade" 
          :rules="[rules.required]">
            <v-rating
              class="ma-auto"
              v-model="submissionSheet.obj.grade" 
              x-large
              color="primary"
              background-color="primary"
            >
            </v-rating>
          </v-input>
        </v-form>
      </template> 
    </confirmation-dialog>
    <!-- undo accept dialog -->
    <confirmation-dialog
      title="Are you sure you want to undo acceptance of this submission?"
      description="This action will notify the student about that their submission is put on hold."
      :visible="showUndoAcceptDialog"
      @successCallback="undoAcceptSubmission"
      @failureCallback="showUndoAcceptDialog = false"
    />
    <!-- delete dialog -->
    <confirmation-dialog
      :visible="showDeleteDialog"
      title="Are you sure you want to delete this submission?"
      description="This action cannot be undone"
      @successCallback="deleteSubmission"
      @failureCallback="showDeleteDialog = false"
      delete-dialog
    />
  </div>
</template>
<script>
import moment from "moment";
import Mixins from "@utils/mixins";
import SubmissionDiscussion from "@components/books/SubmissionDiscussion";
import { mapActions } from "vuex";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
export default {
  name: "TopicAssignmentSubmission",
  mixins: [Mixins.essentials, Mixins.downloadEssentials, Mixins.pullToRefresh],
  components: {
    SubmissionDiscussion,
    IntractCreateEditSheet,
  },
  data() {
    return {
      showDeleteDialog: false,
      featureName: "Assignment",
      submissionDiscussionKey: null,
      moment: moment,
      showAcceptDialog: false,
      showUndoAcceptDialog: false,
      submission: null,
      submissionSheet: {
        visible: false,
        createSuccessMessage: "Assignment submitted successfully!",
        editId: null,
        obj: {
          student: null,
          topic_assignment: this.$route.params.assignmentId,
          grade: null,
        },
      },
      assignmentGradingForm: false,
      rules: {
        required: (v) => !!v || "Required",
      },
    };
  },
  computed: {
    documentsList() {
      return this.submission.topic_assignment_submission_documents;
    },

    submissionFormFields() {
      return {
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        topic_assignment_submission_documents: {
          fieldType: CreateEditFieldTypes.FILEUPLOAD,
          multiple: true,
          label: "Documents",
          helper: "PDF, Video, Audio, Docs (Upto 30MB)",
        },
      };
    },
  },
  methods: {
    ...mapActions(["setAppBarOptions", "resetAppBarOptions"]),
    async getSubmissionDetails() {
      console.log(this.endpoints.topicAssignmentSubmissions);
      var url =
        this.endpoints.topicAssignmentSubmissions +
        this.$route.params.submissionId +
        "/";
      this.submission = await this.api.call(this.essentials, url);
      this.checkIfDocumentsDownloaded();
    },

    async acceptSubmission() {
      this.showAcceptDialog = false;
      var url =
        this.endpoints.topicAssignmentSubmissions + this.submission.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { accepted: true, grade: this.submissionSheet.obj.grade }
      );
      if (response) {
        this.submission.accepted = true;
        this.submission.grade = this.submissionSheet.obj.grade
        this.showSnackbar({
          title: "This submission has been graded & accepted!",
          text: "The student will be notified",
          type: "success",
        });
      }
    },

    async undoAcceptSubmission() {
      this.showUndoAcceptDialog = false;
      var url =
        this.endpoints.topicAssignmentSubmissions + this.submission.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { accepted: false, }
      );
      if (response) {
        this.submission.accepted = false;
        this.showSnackbar({
          title: "Acceptance status has been successfully changed!",
          text: "The student will be notified",
          type: "success",
        });
      }
    },

    async deleteSubmission() {
      this.showDeleteDialog = false;
      var url =
        this.endpoints.topicAssignmentSubmissions +
        this.$route.params.submissionId +
        "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Submission has been successfully deleted",
          text: "The associated faculty will be notified",
          type: "success",
        });
        this.$router.back();
      }
    },
    async onRefresh() {
      this.getSubmissionDetails();
      this.submissionDiscussionKey = this.Helper.generateUID();
    },

    async setAppBar() {
      if (this.currentUser.id != this.submission.student.id || this.submission.accepted) return;
      this.setAppBarOptions({
        actions: [
          {
            id: 2,
            title: "Edit",
            icon: "mdi-pencil",
            onClick: () => {
              this.submissionSheet.visible = true;
              this.submissionSheet.editId = this.submission.id;
            },
          },
          {
            id: 3,
            title: "Delete",
            icon: "mdi-delete",
            onClick: () => (this.showDeleteDialog = true),
          },
        ],
      });
    },
  },
  beforeDestroy() {
    this.resetAppBarOptions();
  },
  async created() {
    await this.getSubmissionDetails();
    this.setAppBar();
    if (this.currentUser.is_student){
      this.submissionSheet.obj.student = this.currentUser.id;
      this.submissionSheet.obj.topic_assignment = this.submission.topic_assignment;
    }
  },
};
</script>